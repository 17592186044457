import './App.css';
import React, { useState } from 'react';
import About from './components/About';
import Home from './components/Home';
import NavBar from './components/NavBar';
import Skills from './components/Skills';
import Work from './components/Work';
import ContactForm from './components/ContactForm';
import ResumePage from './components/ResumePage';

function App() {
  const [pdfViewerActive, setPdfViewerActive] = useState(false);

  const togglePdfViewerActive = () => {
    setPdfViewerActive(!pdfViewerActive);
  };

  return (
    <div className="App bg-[#0a192f]">

      { pdfViewerActive !== true ? (
        <>
          <NavBar togglePdfViewerActive={togglePdfViewerActive} />
          <Home />
          <About />
          <Skills />
          <Work />
          <ContactForm />
        </>
      ) : <ResumePage togglePdfViewerActive={togglePdfViewerActive} />}
    </div>
  );
}

export default App;
