import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { CgClose } from 'react-icons/cg';
import jdNewloveLogo from '../assets/jdnewlovecom_logo.png';

function ResumePage(props) {
  const [width, setWidth] = useState(250);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const { togglePdfViewerActive } = props;

  const onDocumentLoadSuccess = (val) => {
    setNumPages(val.numPages);
    const pfdColumnWidth = document.getElementById('pdf_document_container').offsetWidth;
    if (pfdColumnWidth > 450) {
      setWidth(400);
    }
  };

  const incrementPage = () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  };

  const decrementPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const printError = (err) => true;

  return (
    <>
      {/* alt header */}
      <div className="fixed w-full h-[80px] flex justify-between items-center px-4 z-10 text-white bg-[#0a192f]">
        <div>
          <img className="w-[200px]" src={jdNewloveLogo} alt="Logo" />
        </div>

        <button
          className="
                  border-2
                  border-pink-600
                  m-4 bg-[#0a192f]
                  hover:bg-pink-600
                  rounded-full
                  h-[35px]
                  w-[35px]
                  md:h-[50px]
                  md:w-[50px]
                  flex
                  justify-center
                  items-center"
          type="button"
          onClick={togglePdfViewerActive}
        >
          <CgClose aria-label="close pdf page" />
        </button>

      </div>

      {/* resume page container */}
      <div className="bg-[#0a192f] w-full h-screen min-h-[1200px] text-white px-4">

        <div className="max-w-[1000px] grid grid-cols-1 md:grid-cols-2 mx-auto h-full">

          <div className="flex flex-col w-full justify-center gap-0 md:mb-4">
            <h1 className="font-bold mb-3">Technical Resume 2022</h1>
            <p>
              This version of my resume is restricted to technical related work only. Feel free to download and share a copy for review. Don&apos;t hesitate to contact me should you have any questions. Constructive feedback is always welcome!
            </p>
            <div className="my-6">

              <a href="./jd_resume_2022_about_full.pdf" className="block w-[300px] mb-8">
                <span className="text-white border-2 px-6 py-3 my-2 mx-auto hover:bg-pink-600 hover:border-pink-600">

                  Download Full Version
                </span>
              </a>
              <a href="./jd_resume_2022_about_compressed.pdf" className="block w-[400px]">
                <span className="text-white border-2 px-6 py-3 my-2 hover:bg-pink-600 hover:border-pink-600">
                  Download Compressed Version
                </span>
              </a>

            </div>
          </div>

          <div id="pdf_document_container" className="flex justify-around w-full h-[300px] md:h-full items-center">
            <div className="flex flex-col">
              <p className="mb-2">Preview</p>

              <Document file="./jd_resume_2022_about_compressed.pdf" onLoadSuccess={onDocumentLoadSuccess}>
                <Page width={width} pageNumber={pageNumber} canvasBackground="#db2678" />
              </Document>

              <div className="flex justify-center mt-3">

                {pageNumber}
                {' '}
                of
                {' '}
                {numPages}
              </div>

              <div className="flex justify-center">
                <button
                  className="
                  border-2
                  border-pink-600
                  m-4 bg-[#0a192f]
                  hover:bg-pink-600
                  rounded-full
                  h-[50px]
                  w-[50px]
                  flex
                  justify-center
                  items-center"
                  type="button"
                  onClick={decrementPage}
                >
                  <AiOutlineLeft aria-label="next page pdf" />
                </button>
                <button
                  className="
                  border-2
                  border-pink-600
                  m-4 bg-[#0a192f]
                  hover:bg-pink-600
                  rounded-full
                  h-[50px]
                  w-[50px]
                  flex
                  justify-center
                  items-center"
                  type="button"
                  onClick={incrementPage}
                >
                  <AiOutlineRight aria-label="next page pdf" />

                </button>
              </div>
            </div>

          </div>

        </div>

        <div id="pdfDocument" className="w-[300px]">

          <p>
            Page
            {' '}
            {pageNumber}
            {' '}
            of
            {' '}
            {numPages}
          </p>
        </div>
      </div>
    </>
  );
}

ResumePage.defaultProps = {
  togglePdfViewerActive: console.log('attempting to toggle task'),
};

ResumePage.propTypes = {
  togglePdfViewerActive: PropTypes.func,
};

export default ResumePage;
