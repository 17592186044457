import React from 'react';
import WorkTile from './WorkTile';
import TurnstilLandingPage from '../assets/turnstil/turnstil_landing_page.png';
import NLELandingPage from '../assets/nle/nle_landing_page.png';

const turnstilWorkData = {
  title: 'Turnstil.Cloud',
  role: 'Backend Developer - Director of operations',
  description: 'Turnstil.Cloud is Artificial Intelligence (AI) coupled with Machine Learning that acts as an intelligent interface to control the Cloudflare Firewall while also providing comprehensive analytics and deep insight into the bot traffic on your website.',
  backgroundImage: TurnstilLandingPage,
  url: 'https://turnstil.cloud',
};

const nleWorkData = {
  title: 'NonLinear Educating',
  role: 'Full-Stack Developer - Project Manager',
  description: 'NonLinear Educating provides virtual teaching tools to academic and commercial partners. These tool include access to an extensive private catalog of video on demand tutorials, as well as the ability to create and manage  interactive assessment, lecture and assignment.',
  backgroundImage: NLELandingPage,
  url: 'https://nonlineareducating.com',

};

function Work() {
  return (
    <div name="work" className="bg-[#0a192f] w-full md:h-screen text-gray-300 min-h-[600px]">
      <div className="max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full">
        <div className="pb-8">
          <p className="text-4xl font-bold inline border-b-4 border-pink-600">Industry Work</p>
        </div>

        {/* Work 1 */}

        <WorkTile workData={turnstilWorkData} />

        <WorkTile workData={nleWorkData} />

      </div>
    </div>
  );
}

export default Work;
