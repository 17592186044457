import React from 'react';

function About() {
  return (
    <div name="about" className="w-full h-screen bg-[#0a192f] text-gray-300 min-h-[1200px]">
      <div className="flex flex-col justify-center items-center w-full h-full">
        <div className="max-w-[1000px] w-full grid grid-cols-2 gap-8">
          <div className="sm:text-right pb-8 pl-4">
            <p className="text-4xl font-bold inline border-b-4 border-pink-600">About</p>
          </div>
          <div />
        </div>
        <div className="max-w-[1000px] w-full grid sm:grid-cols-2 gap-8 px-4 sm:gid-cols-2">
          <div>
            <p className="text-4xl font-bold mb-2">“Problems are inevitable but they&apos;re also soluble.”</p>
            <p className="mb-1">David Deutsch</p>
            <p className="mb-1">(Co-Inventor of the theory of quantum computation)</p>
          </div>
          <div>
            <p className="py-4">As a lover of patterns, I&apos;ve often wondered what motivates my interest in what seem to be many disparate passions. My running hypothesis is my desire to make sense of the world, to seek good explanations for its many problems.</p>
            <p className="py-4">Over the past seven years of my corporate life, I&apos;ve grown from an account manager to a product manager to a software engineer. This progression has been fueled by a need to evolve and adapt to the problems at hand.</p>
            <p className="py-4">I aspire to work with teams that have a definitive vision of the future they&apos;re trying to build. Teams that respect the quality of an idea over the seniority of its progenitor. Teams that seek elegant solutions to meaningful problems, and that seek to maximize the contribution and development of each team member.</p>
            <p className="py-4">I aim to continue my journey cultivating experience developing full-stack web applications. We live in an era where the web&apos;s reach has permeated all facets of our world. Designing and building meaningful tools to serve society is my principal goal.</p>
          </div>
        </div>
      </div>
    </div>

  );
}

export default About;
