import React from 'react';
import { BsCircle, BsCheck2Circle } from 'react-icons/bs';

function ContactForm() {
  const [inputs, setInputs] = React.useState({
    validName: false,
    name: '',
    validEmail: false,
    email: '',
    validMessage: false,
    message: '',
  });

  const updateName = (event) => {
    setInputs({
      ...inputs,
      name: event.target.value,
      validName: event.target.value.length > 1,
    });
  };

  const updateEmail = (event) => {
    const newEmailValue = event.target.value;

    // eslint-disable-next-line prefer-regex-literals
    const validEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(newEmailValue);

    setInputs({
      ...inputs,
      email: newEmailValue,
      validEmail,
    });
  };

  const updateMessage = (event) => {
    const newMessage = event.target.value;

    setInputs({
      ...inputs,
      message: newMessage,
      validMessage: newMessage.length > 9,
    });
  };

  return (
    <div name="contact" className="w-full h-screen bg-[#0a192f] flex justify-center items-center p-4 text-gray-900 pb-10">
      <form action="https://getform.io/f/04145903-f1fc-4b82-a953-ec53699e9c46" method="POST" className="flex flex-col px-6 max-w-[600px] w-full">
        <div className="pb-8">
          <p className="text-4xl font-bold inline border-b-4 border-pink-600 text-gray-300">Contact</p>
          <p className="text-grey-300 py-4 text-gray-300">Submit the form below and I&apos;ll reach out to you asap!</p>
        </div>

        <div className="flex w-100 flex-col">

          <div className="w-full flex flex-row items-center">
            { inputs.validName ? (<BsCheck2Circle className="text-pink-500 absolute -translate-x-8" size={20} />)
              : (<BsCircle className="text-pink-500 absolute -translate-x-8" size={20} />) }

            <input onChange={updateName} className="my-2 w-full bg-[#ccd6f6] p-2" type="text" placeholder="Name" name="name" />
          </div>

          <div className=" font-thin text-sm w-full text-right text-pink-300">Minimum length 2 chars</div>

        </div>

        <div className="flex w-100 flex-col">

          <div className="w-full flex flex-row items-center">
            { inputs.validEmail ? (<BsCheck2Circle className="text-pink-500 absolute -translate-x-8" size={20} />)
              : (<BsCircle className="text-pink-500 absolute -translate-x-8" size={20} />) }
            <input onChange={updateEmail} className="my-2 w-full bg-[#ccd6f6] p-2" type="email" placeholder="email" name="email" />
          </div>

          <div className=" font-thin text-sm w-full text-right text-pink-300">Must be a valid email</div>

        </div>

        <div className="flex w-100 flex-col" />

        <div className="w-full flex flex-row items-center">
          { inputs.validMessage ? (<BsCheck2Circle className="text-pink-500 absolute -translate-x-8" size={20} />)
            : (<BsCircle className="text-pink-500 absolute -translate-x-8" size={20} />) }
          <textarea onChange={updateMessage} className="my-2 w-full bg-[#ccd6f6] p-2" placeholder="Message" rows="10" name="message" />
        </div>

        <div className="font-thin text-sm w-full text-right text-pink-300">Minimum length 10</div>

        <div className="flex w-100 items-center">
          <button disabled={!inputs.validName || !inputs.validEmail || !inputs.validMessage} className="text-white border-2 hover:bg-pink-600 hover:border-pink-600 px-4 py-3 mx-auto flex items-center" type="submit">Let&apos;s Collaborate</button>
        </div>

      </form>
    </div>
  );
}

export default ContactForm;
