import React, { useEffect } from 'react';
import { HiArrowNarrowRight } from 'react-icons/hi';
import { Link } from 'react-scroll';

function Home() {
  return (
    <div name="home" className="bg-[#0a192f] w-full h-screen min-h-[500px]">
      <div className="max-w-[1000px] mx-auto px-8 flex flex-col justify-center h-full text-white">
        <p className="text-pink-600">Hi, my name is</p>
        <h1 className="text-4xl sm:text-7xl font-bold text-[#ccd6f6]">JD Newlove</h1>
        <h2 className="text-4xl ms:text-7xl font-bold text-[#8892b0]">I&apos;m a Full Stack developer.</h2>
        <p className="text-[#8892b0] py-4 max-w-[700px]">
          Aiding in the discovery of elegant solutions to interesting problems is my
          lifeblood and I&apos;m on the lookout for people and teams that share my preoccupation.
        </p>
        <div>
          <Link to="work" smooth duration={500}>
            <button className="text-white group border-2 px-6 py-3 my-2 flex items-center hover:bg-pink-600 hover:border-pink-600" type="button">
              View Work
              <span className="group-hover:rotate-90 duration-200">
                <HiArrowNarrowRight className="ml-3" />
              </span>
            </button>
          </Link>

        </div>
      </div>
    </div>

  );
}

export default Home;
