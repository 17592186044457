import React from 'react';
import PropTypes from 'prop-types';
import WorkImg from '../assets/skills/defaultWorkImage.jpeg';

function WorkTile({ workData }) {
  const {
    title, role, description, url, backgroundImage,
  } = workData;

  return (

    <div className="my-[30px]">

      <div>
        <h1 className="text-2xl font-semibold py-1">{title}</h1>
        <h2 className="text-xl py-1">{role}</h2>
      </div>

      <div style={{ backgroundImage: `url(${backgroundImage})`, backgroundPosition: 'top' }} className="content-div w-full shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto my-4">

        {/* hover effects */}
        <div className="opacity-0 group-hover:opacity-100">

          <div className="font-2xl font-bold text-white p-4">
            {description}
          </div>
          <div className="pt-8 text-center">

            <a href="/">
              <button className="text-center rounded-lg px-4 py-3 m-2 bg-gray-400 text-gray-800 font-bold text-lg" type="button" disabled>Learn More</button>
            </a>
            <a href={url}>
              <button className="text-center rounded-lg px-4  py-3 m-2 bg-white text-gray-700 font-bold text-lg" type="button">Visit</button>
            </a>

          </div>
        </div>

      </div>

      <div className="divide-y divide-solid bg-slate-300 h-[1px]  mx-auto mt-[40px]" />

    </div>
  );
}

WorkTile.propTypes = {
  workData: PropTypes.PropTypes.exact({
    title: PropTypes.string,
    role: PropTypes.string,
    url: PropTypes.string,
    description: PropTypes.string,
    backgroundImage: PropTypes.string,
  }),

};

WorkTile.defaultProps = {
  workData: {
    title: 'Example TItle',
    role: 'Example Role',
    url: '/',
    description: 'Example Description',
    backgroundImage: WorkImg,
  },

};

export default WorkTile;
