import React, { useState } from 'react';
import {
  FaBars, FaTimes, FaLinkedin,
} from 'react-icons/fa';
import PropTypes from 'prop-types';
import { HiOutlineMail } from 'react-icons/hi';
import { BsFillPersonLinesFill } from 'react-icons/bs';
import { Link } from 'react-scroll';
import jdNewloveLogo from '../assets/jdnewlovecom_logo.png';

function NavBar(props) {
  const [nav, setNave] = useState(false);
  const handleClick = () => setNave(!nav);
  const { togglePdfViewerActive } = props;

  const viewResume = () => {
    handleClick();
    togglePdfViewerActive();
  };

  return (
    <div className="fixed w-full h-[80px] flex justify-between items-center px-4 text-white bg-[#0a192f]">
      <div>
        <img className="w-[200px]" src={jdNewloveLogo} alt="Logo" />
      </div>
      {/* menu */}
      <div>
        <ul className="hidden md:flex">
          <li>
            <Link to="home" smooth duration={500}>
              Home
            </Link>
          </li>
          <li>
            <Link onClick={handleClick} to="about" smooth duration={500}>
              About
            </Link>
          </li>
          <li>
            <Link onClick={handleClick} to="skills" smooth duration={500}>
              Skills
            </Link>
          </li>
          <li>
            <Link onClick={handleClick} to="work" smooth duration={500}>
              Work
            </Link>
          </li>
          <li>
            <Link onClick={handleClick} to="contact" smooth duration={500}>
              Contact
            </Link>
          </li>
        </ul>
      </div>
      {/* Hamburger */}
      <div onClick={handleClick} onKeyDown={handleClick} tabIndex={0} className="md:hidden z-10" role="button">
        {!nav ? <FaBars size={20} /> : <FaTimes size={20} />}
      </div>
      {/* mobile menu */}
      <div className={!nav ? 'hidden' : 'absolute top-0 left-0 w-full h-screen flex flex-col justify-center items-center bg-[#0a192f] md:hidden'}>
        <ul>
          <li className="py-6 text-3xl">
            <Link onClick={handleClick} to="home" smooth duration={500}>
              Home
            </Link>
          </li>
          <li className="py-6 text-3xl">
            <Link onClick={handleClick} to="about" smooth duration={500}>
              About
            </Link>
          </li>
          <li className="py-6 text-3xl">
            <Link onClick={handleClick} to="skills" smooth duration={500}>
              Skills
            </Link>
          </li>
          <li className="py-6 text-3xl">
            <Link onClick={handleClick} to="work" smooth duration={500}>
              Work
            </Link>
          </li>
          <li className="py-6 text-3xl">
            <Link onClick={handleClick} to="contact" smooth duration={500}>
              Contact
            </Link>
          </li>
          <li className="py-6 text-3xl">
            <button type="button" onClick={viewResume}>
              Resume
            </button>
          </li>
        </ul>
      </div>
      {/* Social */}
      <div className="hidden lg:flex fixed flex-col top-[35%] left-0">
        <ul>
          <li className="w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#0a66c2]">
            <a className="flex justify-between items-center w-full text-gray-300" href="https://www.linkedin.com/in/jd-newlove-6b81b2125/">
              LinkedIn
              <FaLinkedin size={30} />
            </a>
          </li>
          <li className="w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#6fc2b0]">

            <Link className="w-full" onClick={handleClick} to="contact" smooth duration={500}>
              <div className=' w-full flex justify-between items-center text-gray-300"'>
                <div>Email</div>
                <HiOutlineMail size={30} />
              </div>
            </Link>

          </li>
          <li className="w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-black">
            <button type="button" onClick={togglePdfViewerActive} className="flex justify-between items-center w-full text-gray-300">
              Resume
              <BsFillPersonLinesFill size={30} />
            </button>
          </li>
        </ul>

      </div>
    </div>
  );
}

NavBar.defaultProps = {
  togglePdfViewerActive: console.log('attempting to toggle task'),
};

NavBar.propTypes = {
  togglePdfViewerActive: PropTypes.func,
};

export default NavBar;
