import React from 'react';
import html from '../assets/skills/html.png';
import css from '../assets/skills/css.png';
import javascript from '../assets/skills/javascript.png';
import typescript from '../assets/skills/typescript.png';
import node from '../assets/skills/node.png';
import tailwind from '../assets/skills/tailwind.png';
import jest from '../assets/skills/jest.png';
import mysql from '../assets/skills/mysql.png';

function Skills() {
  return (
    <div name="skills" className="bg-[#0a192f] w-full h-screen px-4 min-h-[900px]">
      <div className="max-w-[1000px] mx-auto flex flex-col justify-center w-full h-full text-gray-300">
        <div>

          <p className="text-4xl text-gray-300 border-b-4 border-pink-600">Skills</p>
          <p className="py-4">These are the web technologies I&apos;m most familiar with.</p>

        </div>
        <div className="w-full grid grid-cols-2 sm:grid-cols-4 gap-4 text-center py-8">

          <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500 my-4">
            <img className="mx-auto w-20" src={html} alt="HTML icon" />
            <p className="my-4">HTML</p>
          </div>
          <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500 my-4">
            <img className="mx-auto w-20" src={css} alt="CSS icon" />
            <p className="my-4">CSS</p>
          </div>
          <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500 my-4">
            <img className="mx-auto w-20" src={tailwind} alt="Tailwind icon" />
            <p className="my-4">Tailwind</p>
          </div>
          <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500 my-4">
            <img className="mx-auto w-20" src={javascript} alt="javascript icon" />
            <p className="my-4">Javascript</p>
          </div>
          <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500 my-4">
            <img className="mx-auto w-20" src={typescript} alt="typescript icon" />
            <p className="my-4">TypeScript</p>
          </div>
          <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500 my-4">
            <img className="mx-auto w-20" src={node} alt="node.js icon" />
            <p className="my-4">Node.js</p>
          </div>
          <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500 my-4">
            <img className="mx-auto w-20" src={jest} alt="jest.js icon" />
            <p className="my-4">Jest.js</p>
          </div>
          <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500 my-4">
            <img className="mx-auto w-20" src={mysql} alt="mySql icon" />
            <p className="my-4">MySQLs</p>
          </div>

        </div>

      </div>

    </div>
  );
}

export default Skills;
